<script>
import BottomNav from '@/components/Themes/dayonedayone/BottomNav'
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    BottomNav,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      isLoading: false,
      error: null,
      show: false,
    };
  },
  computed: {
    ...mapState('loyalty', {
      rewards: (state) => state.rewards,
      tiers: (state) => state.tiers,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
  },
  methods: {
    ...mapActions('loyalty', ['getLoyaltyRewards', 'getLoyaltyTiers']),
    getLoyaltyRewardsByTier(loyaltyTierId) {
      return this.rewards.filter(r => Object.prototype.hasOwnProperty.call(r.tiers, loyaltyTierId));
    },
  },
  mounted() {
    this.getLoyaltyTiers({
      merchantId: this.profile.merchant_id,
    });

    this.getLoyaltyRewards({
      merchantId: this.profile.merchant_id,
    });
  },
};
</script>
<template>
  <v-sheet 
    class="reward-list-sheet mx-auto pt-2 px-6"
    elevation="0"
  >
    <v-sheet 
      ref="reward-sheet"
      class="reward-sheet pt-2 pb-2 mx-auto"
      elevation="0"
    >
      <div class="text-subtitle-2 dark--text font-weight-bold ml-4">DayOne DayOne</div>
      <div class="text-caption dark--text text--lighten-2 font-weight-6 ml-4">Priviledge Member Tier</div>

      <div
        class="mt-6"
        :key="`tier-${tier.id}`"
        v-for="tier in tiers"
      >
        <v-row no-gutters>
          <v-col class="p-2" cols="2">
            <v-img
              class="text-center mx-auto"
              position="center"
              :src="`${cdnUrl}/assets/images/loyalty/${profile.hq_merchant_id}/tiers/${tier.sortOrder}.webp`"
            ></v-img>
          </v-col>
          <v-col class="align-self-center p-2" cols="10">
            <div class="text-body-2 font-weight-bold">{{ tier.name }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-slide-group 
              mobile-breakpoint="1024"
              show-arrows="desktop"
              class="tier-reward-slider"
            >
              <v-slide-item
                class="reward-slider-item text-body-2 mx-2"
                :key="`reward-item-${reward.id}`"
                v-for="reward in getLoyaltyRewardsByTier(tier.id)"
              >
                <v-card color="transparent" class="text-left" elevation="0">
                  <v-card-title class="subtitle-2 bg-transparent p-2">
                    <v-row no-gutters>
                      <v-col class="align-self-center" cols="4">
                        <div style="font-size: 8px;">Redeem with</div>
                        <div class="text-body-2 font-weight-bold">{{ reward.tiers[tier.id] }} pts</div>
                      </v-col>
                      <v-col
                        cols="8"
                        :style="`
                          background-image: url(${cdnUrl}/${reward.cover});
                          background-position: center right;
                          background-size: contain;
                          height: 10dvh;
                        `"
                      >
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text class="pb-2 pt-1">
                    <div style="font-size: 8px;">Reward</div>
                    <div class="text-body-2 font-weight-bold">{{ reward.code }}</div>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
    <BottomNav :fixed="true"/>
  </v-sheet>
</template>
<style scoped>
.reward-list-sheet {
  background-image: url('~@/assets/dayonedayone/images/profile-background.webp');
  background-position: center center;
  background-size: cover;
  background-color: #f7efe8;
  width: 100%;
  height: 100%;
  padding-bottom: 45px;
}

.reward-list-sheet .reward-sheet {
  background-color: transparent;
}

.reward-list-sheet /deep/ .reward-slider-item.v-card {
  width: 220px;
}

.reward-list-sheet /deep/ .reward-slider-item.v-card > .v-card__title {
  background-color: #f7efe8;
}

.reward-list-sheet /deep/ .reward-slider-item.v-card > .v-card__text {
  background-color: #efe6df;
  border-radius: 4px;
}

.reward-list-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

@media screen and (min-width: 1024px) {
  .reward-list-sheet /deep/ .reward-slider-item.v-card {
    width: 180px;
  }
}
</style>